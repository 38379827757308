<template>
  <div v-if="channel" class="flex h-full flex-1 flex-col items-center justify-center">
    <div>
      <ChatImage
        class="mb-2"
        size="xl"
        :class="external ? '' : 'cursor-pointer'"
        :show-online="true"
        :item="channel"
        :channel-name="channelName"
        :is-channel="true"
        @click="external ? '' : $emit('toggle:chat-settings')"
      />
    </div>
    <BaseTooltip>
      <template #target>
        <div v-if="channel.data.direct_messaging" class="text-xl font-semibold">{{ truncate(channelName, 40) }}</div>
        <div v-else class="font-semibold" :class="external ? 'text-center text-sm' : 'text-xl'">
          Welcome to {{ truncate(channelName, 40) }}
        </div>
      </template>
      <template #default v-if="channelName.length > 40">{{ channelName }}</template>
    </BaseTooltip>
    <div class="my-3 flex justify-center" v-if="!channel.data.direct_messaging && broadcastChannelAndAdmin(channel)">
      <ChatImage
        v-if="totalChannelMembers > 0"
        class="cursor-pointer"
        custom-class="text-sm bg-gray-400 text-gray-700"
        :item="{ name: '+' + totalChannelMembers }"
        :style="external ? 'margin-right: -60px' : 'margin-right: -70px'"
        :size="external ? 'sm' : 'md'"
        @click="external ? '' : $emit('toggle:chat-settings')"
      />
      <div
        v-for="(member, idx) in channelMembers"
        :key="idx"
        :style="[idx == channelMembers.length - 1 ? '' : external ? 'margin-right: -60px' : 'margin-right: -70px']"
      >
        <BaseTooltip>
          <template #target>
            <ChatImage :item="member.user" :size="external ? 'sm' : 'md'" :class="['cursor-pointer']" />
          </template>
          <template #default>{{ member.user.name }}</template>
        </BaseTooltip>
      </div>
    </div>
    <div
      v-if="!showRequestView && broadcastChannelAndAdmin(channel)"
      class="text-center text-xs font-medium text-gray-400"
    >
      This chat was created at {{ toChatTime(channel.data.created_at, 'channel') }}.
    </div>
    <div
      v-if="hasPermissions && !external && broadcastChannelAndAdmin(channel)"
      class="mt-3 flex items-center space-x-2 py-8"
    >
      <BaseButton
        v-if="!channel.data.broadcast_channel"
        size="sm"
        @press="$emitter.$emit('action:add-members', channel.cid)"
      >
        <UserPlusIcon class="h-4 w-4 text-gray-200" />
        <span class="ml-1 text-xs font-medium text-gray-200">Add People</span>
      </BaseButton>
      <BaseButton size="sm" class="group px-5" @press="$emit('toggle:chat-settings')" type="secondary">
        <Cog8ToothIcon class="h-4 w-4 text-gray-400 group-hover:text-white" />
        <span class="ml-1 text-xs font-medium text-gray-400 group-hover:text-white">Settings</span>
      </BaseButton>
    </div>
    <div v-if="showRequestView" class="py-2 text-center text-xs font-medium leading-4 text-gray-400">
      <div>{{ invitedText }}</div>
      <div>They won’t know you’ve seen their messages until you accept.</div>
    </div>
    <div v-if="showRequestView" class="mt-6 flex items-center space-x-2">
      <BaseButton size="sm" class="space-x-1" type="secondary" :disabled="acceptButtonBusy" @press="onAcceptRequest()">
        <CheckIcon class="h-4 w-4 text-green-400" /><span>Accept</span>
      </BaseButton>
      <BaseButton
        size="sm"
        class="space-x-1"
        type="secondary"
        :disabled="declineButtonBusy"
        @press="onDeclineRequest()"
      >
        <XMarkIcon class="h-4 w-4 text-red-400" />
        <span>Decline</span>
      </BaseButton>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { toChatTime } from '@/composeables/filters';
import { getUserName, broadcastChannelAndAdmin } from '@/composeables/chat';
import ChatImage from './ChatImage.vue';
import { truncate } from '@/composeables/filters';
import useEmitter from '@/composeables/emitter';
import { UserPlusIcon, Cog8ToothIcon, XMarkIcon, CheckIcon } from '@heroicons/vue/20/solid';

const $emitter = useEmitter();
const $store = useStore();

const props = defineProps({
  channel: { type: Object, default: () => ({}) },
  channelName: { type: String, default: '' },
  external: { type: Boolean, default: false },
  showRequestView: { type: Boolean, default: false }
});

// CHANNEL MEMBERS
const channelMembers = ref([]);
const totalChannelMembers = ref(0);

function setChannelMembers() {
  let members = Object.values(props.channel.state.members);
  let users = members ? members.filter(x => !x.invited || (x.invited && !x.invite_rejected_at)) : [];
  users.sort((a, b) => a.user.name.localeCompare(b.user.name));
  channelMembers.value = users;
  totalChannelMembers.value = channelMembers.value.length - 5;
  channelMembers.value = channelMembers.value.slice(0, 5).reverse();
}
const client = computed(() => $store.getters.client);
onMounted(() => {
  setChannelMembers();
  client.value.on('user.updated', async event => {
    let members = Object.values(props.channel.state.members).map(x => x.user_id);
    if (members.includes(event.user.id)) {
      setChannelMembers();
    }
  });
  props.channel.on('channel.updated', () => {
    setChannelMembers();
  });
});

// INVITED DATA
const invitedText = computed(() => {
  if (props.showRequestView) {
    let invited = getInvitedBy(props.channel);
    return !props.channel.data.direct_messaging
      ? invited + ' invited you to join this chat.'
      : invited + ' wants to connect.';
  }
  return null;
});

const streamUser = computed(() => $store.getters.streamUser);
const streamUsers = computed(() => $store.getters.streamUsers);
function getInvitedBy(channel) {
  if (Array.isArray(channel.data.invited_users)) {
    let invited = channel.data.invited_users.find(x => x.invited_users.includes(streamUser.value.id));
    return invited ? getUserName(invited.invited_by, streamUsers) : channel.data.created_by.name;
  }
  return channel.data.created_by.name;
}

// ACCEPT AND DECLINE REQUEST
const acceptButtonBusy = ref(false);
function onAcceptRequest() {
  $emitter.$emit('invite:accept', props.channel);
  acceptButtonBusy.value = true;
}

const declineButtonBusy = ref(false);
function onDeclineRequest() {
  $emitter.$emit('invite:reject', props.channel);
  declineButtonBusy.value = true;
}

// OTHERS
const hasPermissions = computed(() => {
  if (
    props.channel.data.channel_admins &&
    props.channel.data.channel_admins.includes(streamUser.value.id) &&
    !props.channel.data.direct_messaging
  ) {
    return true;
  } else {
    return false;
  }
});
</script>
